import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Alert,
  Stack,
  Typography,
  MenuItem, Select, FormControl
} from "@mui/material";
import { DataGrid,GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const RepostEntries = () => {
  const [entries, setEntries] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [pageSize, setPageSize] = useState(10); // Page size
  const [sponsors, setSponsors] = useState([]); // Add sponsors state
  const navigate = useNavigate();

  useEffect(() => {
    fetchEntries();
    fetchSponsors(); // Fetch sponsors list
  }, []);

  const fetchSponsors = async () => {
    try {
      const response = await axios.get("/api/sponsors");
      setSponsors(response.data); // Set sponsors data
    } catch (error) {
      setError("Error fetching sponsors");
    }
  };

  const dataGridStyles = {
    "--DataGrid-overlayHeight": "300px",
    overflow: "clip",
    borderColor: "#E5E7EB",
    backgroundColor: "#FFFFFF",
    fontFamily: "'Inter', sans-serif",
    fontSize: "0.9rem",
  
    // Column Headers
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#F3F4F6", // Light gray background for a modern look
      color: "#111827", // Dark text for better contrast
      fontSize: "1rem", // Slightly larger font size for clarity
      fontWeight: "600", // Semi-bold for emphasis
      textTransform: "none", // Keep headers clean (no capitalization)
      borderBottom: "2px solid #E5E7EB", // Subtle bottom border
      textAlign: "left", // Align text to the left for readability
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "700", // Stronger emphasis on header titles
        fontSize: "0.95rem", // Slightly refined size for clarity
      },
      "&:hover": {
        backgroundColor: "#E5E7EB", // Subtle hover effect on headers
      },
    },
  
    // Footer Container
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: "#F9FAFB",
      borderTop: "1px solid #E5E7EB",
      fontSize: "0.85rem",
      fontWeight: "500",
    },
  
    // Checkbox Customizations
    "& .MuiCheckbox-root": {
      padding: "4px",
      "& > svg": {
        fontSize: "1rem",
      },
    },
  
    // Pagination Controls
    "& .MuiTablePagination-root": {
      marginRight: "8px",
      "& .MuiIconButton-root": {
        maxHeight: "32px",
        maxWidth: "32px",
        "& > svg": {
          fontSize: "1rem",
        },
      },
    },
  
    // Cell Styling
    "& .MuiDataGrid-cell": {
      borderTop: "1px solid #E5E7EB",
      color: "#374151", // Neutral dark gray for cells
      fontSize: "0.9rem",
      "&:hover": {
        backgroundColor: "#F3F4F6", // Subtle hover effect for cells
      },
    },
  
    // Row Styling
    "& .MuiDataGrid-row": {
      "&:last-of-type": {
        borderBottom: "1px solid #E5E7EB",
      },
      "&:hover": {
        backgroundColor: "#F9FAFB", // Light hover effect for rows
      },
      "&.Mui-selected": {
        backgroundColor: "#E0E7FF", // Light blue for selected rows
        "&:hover": {
          backgroundColor: "#C7D2FE", // Slightly darker blue on hover
        },
      },
    },
  
    // Status Indicators
    "& .status-online": {
      color: "#10B981", // Vibrant green for online status
      fontWeight: "bold",
      backgroundColor: "#DCFCE7",
      borderRadius: "12px",
      padding: "2px 8px",
      fontSize: "0.85rem",
      textAlign: "center",
    },
    "& .status-offline": {
      color: "#9CA3AF", // Subtle gray for offline status
      fontWeight: "bold",
      backgroundColor: "#F3F4F6",
      borderRadius: "12px",
      padding: "2px 8px",
      fontSize: "0.85rem",
      textAlign: "center",
    },
  };
  
  


  const handleSponsorChange = async (id, sponsorId) => {
    try {
      await axios.post("/api/repostEntries/updateSponsor", { id, sponsorId });
      setSuccess("Sponsor updated successfully");
      fetchEntries();
    } catch (error) {
      setError("Error updating sponsor");
    }
  };

  const fetchEntries = async () => {
    try {
      const response = await axios.get("/api/repostEntries");
      setEntries(response.data);
    } catch (error) {
      setError("Error fetching repost entries");
    }
  };

  const handleDecision = async (id, decision) => {
    try {
      const response = await axios.post("/api/repostEntries/decision", {
        id,
        decision,
      });
      setSuccess(response.data.message);
      fetchEntries();
    } catch (error) {
      setError("Error processing the decision");
    }
  };

  const handleFetchRepostUrls = async () => {
    try {
      const existingUrls = entries.map((entry) => entry.url);
      const response = await axios.post("/api/fetchAndStoreReposts", {
        existingUrls,
      });
      setSuccess(response.data.message);
      fetchEntries();
    } catch (error) {
      setError("Error fetching new repost URLs");
    }
  };

  const handleOriginalUrlClick = (videoId) => {
    navigate(`/videos/${videoId}`);
  };

  const columns = [
    { field: "id", headerName: "#", width: 50, sortable: false },
    {
      field: "original_url",
      headerName: "Original URL",
      width: 220,
      renderCell: (params) => (
        <Button
        component="a"
        href={params.row.video_id ? `/videos/${params.row.video_id}` : "#"}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ textTransform: "none", fontSize: "0.8rem" }}
      >
        {params.value || "N/A"}
      </Button>
      
      ),
    },
    {
      field: "url",
      headerName: "Repost URL",
      width: 220,
      renderCell: (params) => (
        <Button
          href={params.value}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ textTransform: "none", fontSize: "0.8rem" }}
        >
          {params.value}
        </Button>
      ),
    },
    { field: "views", headerName: "Views", width: 150, align: "center" },
    { field: "subtitle_binary", headerName: "Subtitle", width: 200 },
    { field: "upload_time", headerName: "Upload Time", width: 150, align: "center" },
     {
    field: "sponsor",
    headerName: "Sponsor",
    width: 150,
    renderCell: (params) => (
      <FormControl fullWidth>
  <Select
    value={params.row.sponsor_id || ""}
    onChange={(e) =>
      handleSponsorChange(params.row.id, e.target.value === "" ? null : e.target.value)
    }
    displayEmpty
    size="small"
  >
    <MenuItem value="">Select Sponsor</MenuItem>
    {sponsors.map((sponsor) => (
      <MenuItem key={sponsor.id} value={sponsor.id}>
        {sponsor.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

    ),
  },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      align: "center",
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          {params.row.status === "pending" ? (
            <>
              <Button
                size="small"
                variant="contained"
                color="success"
                onClick={() => handleDecision(params.row.id, "accepted")}
                sx={{ fontSize: "0.7rem", padding: "3px 6px" }}
              >
                Accept
              </Button>
              <Button
                size="small"
                variant="contained"
                color="error"
                onClick={() => handleDecision(params.row.id, "rejected")}
                sx={{ fontSize: "0.7rem", padding: "3px 6px" }}
              >
                Reject
              </Button>
            </>
          ) : (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => handleDecision(params.row.id, "pending")}
              sx={{ fontSize: "0.7rem", padding: "3px 6px" }}
            >
              Undo
            </Button>
          )}
        </Stack>
      ),
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
        <Typography
        variant="h5"
        sx={{ mb: 2, fontWeight: "bold", textAlign: "left", color: "#333" }}
      >
        Repost Management
      </Typography>


      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}

    

      <Box>
        <DataGrid
          rows={entries}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
          disableSelectionOnClick
          disableColumnMenu
          rowHeight={40}
          headerHeight={40}
          slots={{ toolbar: GridToolbar }}
          sx={{  width: "1130px"}}
        />
      </Box>
    </Box>
  );
};

export default RepostEntries;

