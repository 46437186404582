import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, CircularProgress, Alert, Table } from '@mui/material';
import axios from 'axios';

const formatLargeNumber = (value) => {
  // Ensure value is a valid number
  const num = parseFloat(value);

  if (isNaN(num)) return 'N/A'; // Return 'N/A' if the value is not a number
  if (num >= 1e6) return `${(num / 1e6).toFixed(2).replace(/\.00$/, '')}M`;
  if (num >= 1e3) return `${(num / 1e3).toFixed(2).replace(/\.00$/, '')}K`;

  return num.toFixed(2).replace(/\.00$/, '');
};


const Scorecards = ({ videoId }) => {
  const [data, setData] = useState({ views: {}, likes: {}, comments: {} });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!videoId) {
      setError('No video ID provided.');
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch values and URL from /api/videoDetails/:videoId
        const detailsResponse = await axios.get(`/api/videoDetails/${videoId}`);
        const { views_total, likes_total, comments_total, url } = detailsResponse.data;

        if (!url) {
          setError('URL not found for the video.');
          setLoading(false);
          return;
        }

        // Fetch delta values from /api/influx/scorecard-data using the URL
        const fields = ['views', 'likes', 'comments'];
        const deltaData = {};
        for (let field of fields) {
          const response = await axios.post('/api/influx/scorecard-data', { url, field });
          deltaData[field] = response.data.delta;
        }

        // Combine both data sets
        setData({
          views: { value: views_total, delta: deltaData.views },
          likes: { value: likes_total, delta: deltaData.likes },
          comments: { value: comments_total, delta: deltaData.comments },
        });
      } catch (err) {
        console.error('Error fetching scorecard data:', err);
        setError('Failed to load scorecard data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [videoId]);

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!data) return <p>No data available</p>;

  const scorecards = [
    {
      title: 'Views',
      value: formatLargeNumber(data.views.value),
      delta: data.views.delta !== null ? `${data.views.delta}%` : 'N/A',
      isPositive: data.views.delta >= 0,
    },
    {
      title: 'Likes',
      value: formatLargeNumber(data.likes.value),
      delta: data.likes.delta !== null ? `${data.likes.delta}%` : 'N/A',
      isPositive: data.likes.delta >= 0,
    },
    {
      title: 'Comments',
      value: formatLargeNumber(data.comments.value),
      delta: data.comments.delta !== null ? `${data.comments.delta}%` : 'N/A',
      isPositive: data.comments.delta >= 0,
    },
  ];

  return (
    <Grid container spacing={2} style={{ justifyContent: 'left' }}>
      {scorecards.map((card, index) => (
        <Grid item key={index}>
          <Card
            style={{
              width: '226px',
              height: '110px',
              backgroundColor: '#fff',
              borderRadius: '8px',
              padding: '5px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              cursor: 'pointer',
              transition: 'transform 0.2s, box-shadow 0.2s',
            }}
            onMouseDown={(e) => {
              e.currentTarget.style.transform = 'scale(1.05)';
              e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.2)';
            }}
            onMouseUp={(e) => {
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
            }}
          >
            <CardContent style={{ padding: '0', textAlign: 'center' }}>
              <Table>
                <tbody>
                  <tr>
                    <td>
                      <Typography variant="body2" style={{ fontWeight: 'bold', color: '#333', fontSize: '25px' }}>
                        {card.title}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="h5" style={{ margin: '5px 0', fontWeight: 'bold', color: '#000' }}>
                        {card.value}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography variant="caption" style={{ color: '#888' }}>
                        Last 2 days
                      </Typography>
                    </td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '5px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '2px 8px',
                            borderRadius: '12px',
                            backgroundColor: card.isPositive ? '#4caf50' : '#ff4d4f',
                            color: '#fff',
                          }}
                        >
                          <span style={{ marginRight: '5px', fontSize: '0.8rem' }}>
                            {card.isPositive ? '▲' : '▼'}
                          </span>
                          <Typography
                            variant="body2"
                            style={{ fontWeight: 'bold', fontSize: '0.8rem' }}
                          >
                            {card.delta}
                          </Typography>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Scorecards;
